<template>
  <v-container>
    <v-row>
      <v-col>

        <v-card>
          <v-card-title>
            {{ $t('groups') }}

            <v-spacer></v-spacer>

            <v-text-field
                id="searchField"
                v-model="search"
                append-icon="mdi-magnify"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-btn color="info" @click="onAdd">
              <v-icon dark left>mdi-plus</v-icon>
              {{ $t('add_group') }}
            </v-btn>
          </v-card-text>

          <v-data-table
              id="dataTable"
              :headers="headers"
              :items="groups"
              :search="search"
              :items-per-page="15"
              @click:row="onClickRow"
          ></v-data-table>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog id="dialog" v-model="dialog" persistent width="75%">
      <v-card>
        <v-form @submit.prevent="save" enctype='multipart/form-data'>
          <v-card-title>{{ $t('edit_group_details') }}</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card-title>
                  <h1>{{ groupDetails.name }}</h1>
                </v-card-title>
                <v-img v-if="groupDetails.preview!=''" :src="'previews/' + groupDetails.preview"></v-img>
              </v-col>

              <v-col cols="6">
                <v-card-text>
                  <div>{{ $t('name') }}:</div>

                  <v-text-field
                      id="groupName"
                      v-model="groupDetails.name"
                      type="text"
                      required
                  ></v-text-field>

                  <div>{{ $t('cultivar') }}:</div>

                  <v-autocomplete
                      id="groupCultivar"
                      :label="$t('cultivar')"
                      v-model="groupDetails.cultivar"
                      :items="this.cultivars"
                      required
                  />

                  <div>{{ $t('variety') }}:</div>

                  <v-autocomplete
                      id="groupVariety"
                      :label="$t('variety')"
                      v-model="groupDetails.variety"
                      :items="this.varieties"
                      required
                  />


                  <div>{{ $t('description') }}:</div>

                  <v-textarea
                      id="groupName"
                      v-model="groupDetails.description"
                      required
                  ></v-textarea>
                  <v-divider class="mt-8 mb-8"></v-divider>

                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn id="cancelButton" color="error"
                   @click="dialog = false"
                   v-shortkey="['esc']" @shortkey="dialog = false"
            >
              <v-icon dark left>mdi-cancel</v-icon>
              {{ $t('cancel') }}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn id="deleteButton" color="error"
                   @click="areyousure=true"
            >
              <v-icon dark left>mdi-delete-alert</v-icon>
              {{ $t('delete') }}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn id="submitButton" type="submit" color="info">
              <v-icon dark left>mdi-send</v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>

        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="areyousure" persistent width="25%">
      <v-card>
        <v-card-title>{{ $t('are_you_sure') }}</v-card-title>

        <v-card-text>
          {{ $t('are_you_sure_delete_group') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text
                 @click="areyousure = false"
                 v-shortkey="['esc']"
          >
            {{ $t('cancel') }}
          </v-btn>

          <v-btn color="blue" text @click="remove">{{ $t('ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
      {{ snackbarMessage }}

      <v-btn text @click="snackbar=false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import groupService from '@/services/GroupService.js'

export default {
  name: "Image groups",

  computed: {
    ...mapState(['isAdmin']),
    ...mapGetters(['getRemark', 'getPriority']),
  },

  created() {
    // Route guard
    if (!this.isAdmin) {
      this.$store.dispatch('logUserOut')
      this.$router.push('/login')
    }

    this.init()
  },

  data() {
    return {
      headers: [
        {text: this.$t('id'), value: 'id'},
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('cultivar'), value: 'cultivar'},
        {text: this.$t('variety'), value: 'variety'}
      ],
      groups: [],
      cultivars: [],
      varieties: [],
      search: '',

      groupDetails: {
        id: '',
        name: '',
        cultivar: '',
        variety: '',
        description: '',
        file: ''
      },

      dialog: false,
      areyousure: false,

      snackbar: false,
      snackbarTop: true,
      snackbarColor: '',
      snackbarMessage: '',
    }
  },

  methods: {
    init() {
      groupService.all()
          .then(response => {
            this.groups = response.data['groups'],
                this.cultivars = response.data['cultivars'],
                this.varieties = response.data['varieties']
          })
          .catch(() => {
            this.snackbar = true
            this.snackbarColor = "error"
            this.snackbarMessage = this.$t('invalid_data')
          })
    },

    onClickRow(rowData) {
      this.dialog = true

      this.groupDetails = rowData
    },
    onAdd() {
      this.dialog = true

      this.groupDetails = {}
    },
    save() {


      groupService.save(this.groupDetails)
          .then(() => {
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarMessage = this.$t('data_saved')
            this.dialog = false
            this.init()
          })
          .catch(() => {
            this.snackbar = true
            this.snackbarColor = "error"
            this.snackbarMessage = this.$t('invalid_data')
          })
    },
    remove() {
      groupService.remove(this.groupDetails)
          .then(() => {
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarMessage = this.$t('data_saved')
            this.dialog = false
            this.areyousure = false
            this.init()
          })
          .catch(() => {
            this.snackbar = true
            this.snackbarColor = "error"
            this.snackbarMessage = this.$t('invalid_data')
          })
    }

  },
}
</script>
